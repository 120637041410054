<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisabled"
      :searchable="isSearchable"
      @search-change="onSearch"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const MasterRepository = RepositoryFactory.get("master");
export default {
  props: {
    isSearchable: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isGetData: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      ids: [],
      params: {
        search: "",
        length: 10,
      },
      isDisabled: false,
      masterData: [
        { code: "draft", name: "Draft" },
        { code: "approved", name: "Released" },
        { code: "rejected", name: "Rejected" },
        { code: "canceled", name: "Canceled" },
        { code: "expired", name: "Expired" },
      ],
    };
  },
  methods: {
    async onSearch(search) {
      if (search.length > 2) {
        this.params.search = search;
        await this.getData();
      }
    },
    async getData() {
      this.options = this.masterData.filter((data) => {
        return data.name
          .toLowerCase()
          .includes(this.params.search.toLowerCase());
      });
    },
    addAllSelect() {
      // this.options.unshift({ code: "all", name: "All" });
      this.selected = { code: "all", name: "All" };
    },
  },
  mounted() {
    if (this.isGetData) {
      this.getData();
      this.addAllSelect();
    }
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].code == "all") {
              v.splice(i, 1);
            }
          }
        }
      }
      this.selected = v;
      this.$emit("data", v);
    },
  },
};
</script>
