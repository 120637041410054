import Repository from "./repository";

const resource = "/api/v1/import";

export default {
  getList(params) {
    return Repository.get(`${resource}/table`, params);
  },
  getDetail(id) {
    return Repository.get(`${resource}/table/${id}`);
  },
};
