<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :custom-label="customLabel"
      @search-change="handleSearch"
      :disabled="isDisable"
      :select-label="''"
      deselect-label=""
      :searchable="isSearchable"
    >
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import _ from "lodash";

const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    showAllLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: [],
      filter: {
        search: "",
        length: 10,
        page: 1,
        level: 1,
      },
    };
  },
  methods: {
    setAllSelected() {
      this.selected = {
        id: 0,
        code: "All",
        name: "Customer Group",
      };
    },
    async setLevel(level) {
      this.filter.level = level;
      await this.getData();
    },
    customLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    handleSearch: _.debounce(function (val) {
      this.filter.search = val;
      this.getData();
    }, 1000),
    async getData() {
      this.$vs.loading();
      try {
        const params = {
          ...this.filter,
        };

        const resp = await MasterRepository.getCustomerGroups({
          params: params,
        });
        if (resp.code == 200) {
          var options = [];
          if (this.showAllLabel) {
            options.push({
              id: 0,
              code: "All",
              name: "Customer Group",
            });
            this.selected = options[0];
          }
          resp.data.records.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching data",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }

      this.$vs.loading.close();
    },
    async setTerritoryID(id) {
      this.filter.territory_id = id;
      await this.getData();
    },
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].id == 0) {
              v.splice(i, 1);
            }
          }
        }
      }
      this.selected = v;
      this.$emit("data", v);
    },
  },
};
</script>
