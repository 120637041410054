<template>
  <div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full mb-2">
        <label class="vs-input--label">SKU Item</label>
        <select-item @data="setItem" :isMultiple="true" :is-show-all-label="true" ref="selectItem" />
      </div>
      <div class="vx-col w-full mb-2">
        <label class="vs-input--label">Item Unit</label>
        <select-item-unit
          @data="setItemUnit"
          ref="selectItemUnit"
          :isMultiple="true"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Territory (Optional)</label>
        <select-territory
          @data="setTerritory"
          :isMultiple="true"
          :showAllLabel="true"
          ref="selectTerritory"
        />
      </div>
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Customer (Optional)</label>
        <select-customer
          @data="setCustomer"
          ref="selectCustomer"
          :disableSearch="true"
        />
      </div>
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Customer Group 1 (Optional)</label>
        <select-customer-group-1
          @data="setCustomerGroup1"
          :isMultiple="true"
          ref="selectCustomerGroup1"
          :showAllLabel="true"
        />
      </div>
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Customer Group 2 (Optional)</label>
        <select-customer-group-2
          @data="setCustomerGroup2"
          :showAllLabel="true"
          :isMultiple="true"
          ref="selectCustomerGroup2"
        />
      </div>
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Customer Group 3 (Optional)</label>
        <select-customer-group-3
          @data="setCustomerGroup3"
          :showAllLabel="true"
          :isMultiple="true"
          ref="selectCustomerGroup3"
        />
      </div>
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Pricing Group (Optional)</label>
        <select-pricing-group
          @data="setPricingGroup"
          :showAllLabel="true"
          :isMultiple="true"
          ref="selectPricingGroup"
        />
      </div>
      <!-- <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Customer Category (Optional)</label>
        <select-customer-category
          @data="setCustomerCategory"
          ref="selectCustomerCategory"
        />
      </div> -->
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Sales Channel (Optional)</label>
        <select-sales-channel
          @data="setSalesChannel"
          :isMultiple="true"
          :showAllLabel="true"
          ref="selectSalesChannel"
        />
      </div>
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Distribution Channel (Optional)</label>
        <select-distribution-channel
          @data="setDistributionChannel"
          :isMultiple="true"
          :showAllLabel="true"
          ref="selectDistributionChannel"
        />
      </div>
      <div class="vx-col w-1/2 mb-2">
        <label class="vs-input--label">Status (Optional)</label>
        <select-status
          @data="setStatus"
          ref="selectStatus"
          :isMultiple="true"
        />
      </div>
      <div class="vx-col w-full mb-2 mt-4 gap-2 flex">
        <vs-button color="primary" @click="() => $emit('data', form)"
          >Submit</vs-button
        >
        <vs-button color="warning" @click="handleExport">Export</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import SelectItem from "@/components/master/Item.vue";
import SelectItemUnit from "@/components/master/ItemUnit.vue";
import SelectCustomer from "@/components/master/CustomerV2.vue";
import SelectTerritory from "@/components/master/Territory.vue";
import SelectCustomerGroup1 from "@/components/master/CustomerGroup.vue";
import SelectCustomerGroup2 from "@/components/master/CustomerGroup.vue";
import SelectCustomerGroup3 from "@/components/master/CustomerGroup.vue";
import SelectPricingGroup from "@/components/master/PricingGroup.vue";
import SelectCustomerCategory from "@/components/master/Supplier.vue";
import SelectStatus from "@/components/master/SelectStatus.vue";
import SelectSalesChannel from "@/components/master/SalesChannel.vue";
import SelectDistributionChannel from "@/components/master/DistributionChannel.vue";

export default {
  components: {
    SelectItem,
    SelectItemUnit,
    SelectCustomer,
    SelectTerritory,
    SelectCustomerGroup1,
    SelectCustomerGroup2,
    SelectCustomerGroup3,
    SelectPricingGroup,
    SelectCustomerCategory,
    SelectStatus,
    SelectSalesChannel,
    SelectDistributionChannel,
  },
  data() {
    return {
      form: {
        sku_codes: [],
        units: [],
        customer_ids: [],
        territory_ids: [],
        customer_group_id_1s: [],
        customer_group_id_2s: [],
        customer_group_id_3s: [],
        customer_category_ids: [],
        pricing_group_ids: [],
        status: [],
        sales_channel_ids: [],
        distribution_channel_ids: [],
      },
    };
  },
  methods: {
    setSalesChannel(result) {
      if (Array.isArray(result)) {
        this.form.sales_channel_ids = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.sales_channel_ids.push(result[i].ID);
        }
        return;
      }
    },
    setDistributionChannel(result) {
      if (Array.isArray(result)) {
        this.form.distribution_channel_ids = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.distribution_channel_ids.push(result[i].ID);
        }
        return;
      }
    },
    handleExport() {
      this.$emit("export", this.form);
    },
    setStatus(result) {
      this.form.status = [];
      if (Array.isArray(result)) {
        result.forEach((element) => {
          if (element.code == "All") return;
          this.form.status.push(element.code);
        });
        return;
      }
    },
    setCustomerCategory(result) {
      if (Array.isArray(result)) {
        this.form.customer_category_ids = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.customer_category_ids.push(result[i].ID);
        }
        return;
      }
    },
    setPricingGroup(result) {
      if (Array.isArray(result)) {
        this.form.pricing_group_ids = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.pricing_group_ids.push(result[i].ID);
        }
        return;
      }
    },
    setCustomerGroup1(result) {
      if (Array.isArray(result)) {
        this.form.customer_group_id_1s = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.customer_group_id_1s.push(result[i].ID);
        }
        return;
      }
    },
    setCustomerGroup2(result) {
      if (Array.isArray(result)) {
        this.form.customer_group_id_2s = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.customer_group_id_2s.push(result[i].ID);
        }
        return;
      }
    },
    setCustomerGroup3(result) {
      if (Array.isArray(result)) {
        this.form.customer_group_id_3s = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.customer_group_id_3s.push(result[i].ID);
        }
        return;
      }
    },
    setItem(result) {
      let skuCodes = [];
      this.form.sku_codes = [];
      for (let i = 0; i < result.length; i++) {
        if (result[i].ID == 0) continue;
        skuCodes.push(result[i].SkuCode);
        this.form.sku_codes.push(result[i].SkuCode);
      }

      this.$refs.selectItemUnit.getDataParams({
        sku_codes: skuCodes,
      });
    },
    setItemUnit(result) {
      if (Array.isArray(result)) {
        this.form.units = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].ID == 0) continue;
          this.form.units.push(result[i].Name);
        }
        return;
      }
    },
    setCustomer(result) {
      if (Array.isArray(result)) {
        this.form.customer_ids = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].id == 0) continue;
          this.form.customer_ids.push(result[i].ID);
        }
        return;
      }
    },
    setTerritory(result) {
      if (Array.isArray(result)) {
        this.form.territory_ids = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].id == 0) continue;
          this.form.territory_ids.push(result[i].id);
        }

        this.$refs.selectCustomer.setFilter({
          length: 0,
          page: 0,
          territory_id: this.form.territory_ids,
        });
        return;
      }
    },
  },
  mounted() {
    this.$refs.selectCustomerGroup1.setLevel(1);
    this.$refs.selectCustomerGroup2.setLevel(2);
    this.$refs.selectCustomerGroup3.setLevel(3);
    this.$refs.selectCustomer.setAllSelected();
    this.$refs.selectItemUnit.setAllSelected();
    this.$refs.selectItem.setLength(50)
  },
};
</script>
