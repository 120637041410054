<template>
  <div>
    <vx-card title="SKU View">
      <Header @data="setSubmit" @export="exportData" />

      <vs-tabs>
        <vs-tab label="List">
          <Table ref="table" />
        </vs-tab>

        <vs-tab label="Log Export">
          <div>
            <TableExport :filters="filterExport" />
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import Table from "./_components/table.vue";
import Header from "./_components/header.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import TableExport from "@/components/export/Table.vue";

const PriceItemRepo = RepositoryFactory.get("priceItem");

export default {
  components: {
    Table,
    Header,
    TableExport,
  },
  data() {
    return {
      filterExport: {
        source: "OMS",
        type: "sku-view-price",
      },
    };
  },
  methods: {
    async validationExport(result) {
      if (result.sku_codes.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please choose SKU",
          color: "danger",
        });
        return false;
      }

      if (result.units.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please choose Unit",
          color: "danger",
        });
        return false;
      }

      return true;
    },
    async exportData(result) {
      this.$vs.loading();
      try {
        const params = {
          sku_codes: result.sku_codes,
          units: result.units,
          customer_ids: result.customer_ids,
          territory_ids: result.territory_ids,
          customer_group_id_1s: result.customer_group_id_1s,
          customer_group_id_2s: result.customer_group_id_2s,
          customer_group_id_3s: result.customer_group_id_3s,
          customer_category_ids: result.customer_category_ids,
          pricing_group_ids: result.pricing_group_ids,
          status: result.status,
          sales_channel_ids: result.sales_channel_ids,
          distribution_channel_ids: result.distribution_channel_ids,
          is_export: true,
        };

        const response = await PriceItemRepo.list({
          params: params,
        });
        if (response.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: "Export Success",
            color: "success",
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Export Failed",
            color: "danger",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Export Failed",
          color: "danger",
        });

        console.log(error);
      }

      this.$vs.loading.close();
    },
    resetFilter() {
      return {
        customer_ids: [],
        territory_ids: [],
        customer_group_id_1s: [],
        customer_group_id_2s: [],
        customer_group_id_3s: [],
        customer_category_ids: [],
        pricing_group_ids: [],
        status: [],
        sales_channel_ids: [],
        distribution_channel_ids: [],
      };
    },
    setSubmit(result) {
      this.$refs.table.resetFilter();
      this.$refs.table.setFilter({
        sku_codes: result.sku_codes,
        units: result.units,
        customer_ids: result.customer_ids,
        territory_ids: result.territory_ids,
        customer_group_id_1s: result.customer_group_id_1s,
        customer_group_id_2s: result.customer_group_id_2s,
        customer_group_id_3s: result.customer_group_id_3s,
        customer_category_ids: result.customer_category_ids,
        pricing_group_ids: result.pricing_group_ids,
        status: result.status,
        sales_channel_ids: result.sales_channel_ids,
        distribution_channel_ids: result.distribution_channel_ids,
      });
      this.$refs.table.getData();
    },
  },
};
</script>
