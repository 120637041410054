<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="SkuCode"
      label="SkuCode"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :custom-label="customLabel"
      @search-change="searchChange"
      :allowEmpty="allowEmpty"
    >
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const MasterRepository = RepositoryFactory.get("master");
import _ from "lodash";

export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isShowAllLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      defaultParams: {
        length: 10,
        page: 1,
      },
    };
  },
  methods: {
    searchChange: _.debounce(function (query) {
      this.defaultParams.search = query;
      if (query != "") {
        this.getData();
      }
    }, 500),
    setLength(length) {
      this.defaultParams.length = length;
    },
    customLabel(option) {
      return `${option.SkuCode} - ${option.Name}`;
    },
    async setSelectedData(data) {
      this.selected = data;
    },
    async setDefaultParams(params) {
      this.defaultParams = params;
    },
    async getData() {
      this.$vs.loading();
      try {
        const resp = await MasterRepository.getItems({
          params: this.defaultParams,
        });
        if (resp.code == 200) {
          if (this.isShowAllLabel) {
            resp.data.records.unshift({
              id: 0,
              SkuCode: "All",
              Name: "Sku",
            });

            if (this.selected == null) {
              this.selected = resp.data.records[0];
            }
          }

          this.options = resp.data.records;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching items",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }

      this.$vs.loading.close();
    },
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].id == 0) {
              v.splice(i, 1);
            }
          }
        }
      }
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    if (this.isGetData) {
      this.getData();
    }
  },
};
</script>
