<template>
  <div>
    <multiselect
      class="selectExample"
      v-model="selected"
      :options="options"
      multiple
      :filterable="true"
      :allow-empty="true"
      :group-select="true"
      :max-height="100"
      :limit="3"
      :internal-search="false"
      placeholder="Type to search"
      track-by="ID"
      @search-change="handleSearch"
      :custom-label="customLabel"
      name="Customer"
      :select-label="''"
      deselect-label=""
      :disabled="isDisable"
      :loading="isLoading"
      :searchable="isSearchable"
    />
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import _ from "lodash";

const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    showAllLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      filter: {
        search: "",
        length: 10,
        page: 1,
      },
      isLoading: false,
    };
  },
  methods: {
    handleSearch: _.debounce(function (val) {
      this.filter.search = val;
      this.getData();
    }, 1000),
    async setSelectedData(data) {
      this.selected = data;
    },
    getData() {
      this.isLoading = true;
      const params = {
        ...this.filter,
      };

      MasterRepository.getPricingGroups({
        params: params,
      })
        .then((resp) => {
          this.isLoading = false;
          if (resp.code == 200) {
            var options = [];
            if (this.showAllLabel && this.selected == null) {
              options.push({
                id: 0,
                code: "All",
                name: "Pricing Group",
              });

              this.selected = options[0];
            }
            resp.data.records.forEach(function (item) {
              options.push(item);
            }, options);
            this.options = options;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching pricing group",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          console.log(error);
        });
    },
    customLabel(option) {
      return `${option.code} - ${option.name}`;
    },
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].id == 0) {
              v.splice(i, 1);
            }
          }
        }
      }
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
