import ImportRepository from "./importRepository";
import dmsRepository from "./dmsRepository";
import salesReturnV2Repository from "./salesReturnV2Repository";
import PriceItemRepository from "./priceItemRepository";
import PricingManagementV3Repository from "./pricingManagementV3Repository";
import MasterRepository from "./masterRepository";
import ExportRepository from "./exportRepository";
import approvalRepository from "./approvalRepository";

const repositories = {
  master: MasterRepository,
  pricingManagementV3: PricingManagementV3Repository,
  import: ImportRepository,
  salesReturnV2: salesReturnV2Repository,
  dms: dmsRepository,
  priceItem: PriceItemRepository,
  export: ExportRepository,
  approval: approvalRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
