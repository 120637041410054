<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisable"
      :select-label="''"
      deselect-label=""
      :searchable="isSearchable"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";

const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    showAllLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
    };
  },
  methods: {
    async setSelectedData(data) {
      this.selected = data;
    },
    async onSearchChange(key) {
      if (key.length > 2) {
        this.getData({ params: { search: key } });
      }
    },
    async getData(params) {
      try {
        const resp = await MasterRepository.getSalesChannels(params);
        if (resp.code == 200) {
          var options = [];
          if (this.showAllLabel && this.selected == null) {
            options.push({
              id: 0,
              code: "",
              name: "All",
            });
            this.selected = options[0];
          }
          resp.data.records.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching sales channel data",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].id == 0) {
              v.splice(i, 1);
            }
          }
        }
      }
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
