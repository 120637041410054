<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      label="name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :custom-label="customLabel"
    >
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const MasterRepository = RepositoryFactory.get("master");
export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      itemID: 0,
    };
  },
  methods: {
    setAllSelected() {
      this.selected = {
        ID: 0,
        Name: "All",
      };
    },
    reset() {
      this.selected = null;
      this.options = [];
    },
    customLabel(option) {
      return `${option.Name}`;
    },
    async setItemID(ID) {
      this.itemID = ID;
      this.getData();
    },
    setItemIDs(IDs) {
      IDs.forEach((ID) => {
        this.itemID = ID;
        this.getDataAppend();
      });
    },
    async setSelectedData(data) {
      this.selected = data;
    },
    async getData() {
      this.$vs.loading();
      try {
        const itemID = this.itemID;
        if (itemID == 0) {
          this.options = [];
          return;
        }
        const resp = await MasterRepository.getItemUnitByItem(itemID);
        if (resp.code == 200) {
          var options = [];
          options.push({
            IO: 0,
            Name: "All",
          });
          this.selected = options[0];
          resp.data.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while get item unit",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }

      this.$vs.loading.close();
    },
    async getDataParams(params) {
      this.$vs.loading();
      try {
        const resp = await MasterRepository.getTableItemUnit(params);
        if (resp.code == 200) {
          var options = [];
          options.push({
            ID: 0,
            Name: "All",
          });
          this.selected = options[0];

          if (resp.data.records == null) {
            this.$vs.loading.close();
            this.options = options;
            return;
          }
          resp.data.records.forEach(function (item) {
            options.push({
              ID: item.id,
              Name: item.unit,
            });
          }, options);
          this.options = options;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while get item unit",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }

      this.$vs.loading.close();
    },
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].ID == 0) {
              v.splice(i, 1);
            }
          }
        }
      }
      this.selected = v;
      this.$emit("data", v);
    },
  },
};
</script>
