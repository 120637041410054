<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      label="Name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisabled"
      :custom-label="customLabel"
      @search-change="handleSearch"
      :select-label="''"
      deselect-label=""
      :searchable="isSearchable"
    >
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import _ from "lodash";

const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
    };
  },
  methods: {
    handleSearch: _.debounce(function (val) {
      this.getData({
        search: val,
        length: 10,
        page: 1,
      });
    }, 1000),
    customLabel(option) {
      return `${option.Name}`;
    },
    async setSelectedData(data) {
      this.selected = data;
    },
    async getData(params) {
      try {
        const resp = await MasterRepository.getSuppliers(params);
        if (resp.code == 200) {
          this.options = [];
          var options = [];
          resp.data.records.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching suppliers",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
  },
  watch: {
    selected(v) {
      if (v) {
        this.$emit("data", v);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
