<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>

      <template slot="thead">
        <vs-th sort-key="sku_code">SKU Code</vs-th>
        <vs-th sort-key="item_name">Item Name</vs-th>
        <vs-th sort-key="item_unit">Item Unit</vs-th>
        <vs-th>Price Rule Code</vs-th>
        <vs-th>Pricing Groups</vs-th>
        <vs-th>Sales Channels</vs-th>
        <vs-th>Territories</vs-th>
        <vs-th>Customer Categories</vs-th>
        <vs-th>Customers</vs-th>
        <vs-th sort-key="qty_from">Qty From</vs-th>
        <vs-th sort-key="qty_to">Qty To</vs-th>
        <vs-th sort-key="price">Price</vs-th>
        <vs-th sort-key="markup">Markup</vs-th>
        <vs-th>Valid From - To</vs-th>
        <vs-th>Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ tr.sku_code }}</vs-td>
          <vs-td>{{ tr.item_name }}</vs-td>
          <vs-td>{{ tr.item_unit }}</vs-td>
          <vs-td>{{ tr.price_rule_code }}</vs-td>
          <vs-td>{{ arrayToString(tr.pricing_groups, ",") }}</vs-td>
          <vs-td>{{ arrayToString(tr.sales_channels, ",") }}</vs-td>
          <vs-td>{{ arrayToString(tr.territories, ",") }}</vs-td>
          <vs-td>{{ arrayToString(tr.customer_categories, ",") }}</vs-td>
          <vs-td>{{ arrayToString(tr.customers, ",") }}</vs-td>
          <vs-td>{{ tr.qty_from }}</vs-td>
          <vs-td>{{ tr.qty_to }}</vs-td>
          <vs-td>{{ numberFormat(tr.price) }}</vs-td>
          <vs-td>{{ numberFormat(tr.markup) }}</vs-td>
          <!-- <vs-td>{{ tr.valid_from }} - {{ tr.valid_to }}</vs-td> -->
          <vs-td
            >{{ dateFormat(tr.valid_from, "DD/MM/YYYY") }} -
            {{ dateFormat(tr.valid_to, "DD/MM/YYYY") }}</vs-td
          >
          <vs-td>{{ tr.status }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import { arrayToString, numberFormat, dateFormat } from "@/utils/helper";
import _ from "lodash";

const PriceItemRepo = RepositoryFactory.get("priceItem");

export default {
  components: {},
  data() {
    return {
      table: this.tableDefaultState(),
      filter: null,
    };
  },
  methods: {
    dateFormat,
    arrayToString,
    numberFormat,
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "desc",
        sort: "created_at",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch: _.debounce(function (val) {
      if (this.filter == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please set filter first",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });

        return;
      }

      this.table.search = val;
      this.table.page = 1;
      this.getData();
    }, 2000),
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (key == "" || active == "") {
        return;
      }
      this.table.order = active;
      this.table.sort = key;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      this.$vs.loading();
      try {
        const params = {
          params: {
            page: this.table.page,
            length: this.table.length,
            order: this.table.order,
            sort: this.table.sort,
            search: this.table.search,
            status: this.table.status,

            ...this.filter,
          },
        };

        const resp = await PriceItemRepo.list(params);
        if (resp.code == 200) {
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          this.table.length = resp.data.total_record_per_page;
          this.table.data = resp.data.records;
          this.setStartEnd();
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while draft sales price",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    resetFilter() {
      this.table.page = 1;
      this.filter = null;
    },
    setFilter(data) {
      this.table.page = 1;
      this.filter = data;
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.table.page = val;
        this.handleChangePage(val);
      },
    },
  },
};
</script>
