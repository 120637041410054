import Repository from "./repository";

const resource = "/api/v1/dms";

export default {
  upload(params) {
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return Repository.post(`${resource}/upload`, params, headers);
  },
};
