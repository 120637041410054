import { get } from "core-js/core/dict";
import Repository from "./repository";

const currency = "/api/v1/master/currency";
const salesChannel = "/api/v1/master/sales-channel";
const distributionChannel = "/api/v1/master/customer-category-all";
const supplier = "/api/v1/master/suppliers";
const pricingGroup = "api/v1/master/pricing-group";
const territory = "api/v1/master/territory";
const sales = "api/v1/master/sales";
const invoice = "/api/v1/invoice/by-code";
const reason = "/api/v1/master/reason";
const customerAddress = "/api/v1/master/customer-address";
const warehouseTerritory = "/api/v1/master/warehouse-territory";
const itemUnit = "/api/v1/master/table-item-unit";
const itemUnitV2 = "/api/v2/master/item-unit";
const item = "api/v1/master/item";
const itemUnitByItem = "api/v1/master/unit-by-item";
const customer = "api/v1/master/customer";
const customerGroup = "api/v1/master/customer-group";
const customerv2 = "api/v1/master/productFestives/customer";
const customerBillTo = "api/v1/master/customer-address/bill-to";
const tax = "api/v1/master/tax";
const warehouse = "api/v1/master/warehouse";

export default {
  getWarehouseByID(id) {
    return Repository.get(`${warehouse}/${id}`);
  },
  getSalesChannels(params) {
    return Repository.get(`${salesChannel}`, params);
  },
  getCurrencies(params) {
    return Repository.get(`${currency}`, params);
  },
  getDistributionChannels(params) {
    return Repository.get(`${distributionChannel}`, params);
  },
  getSuppliers(params) {
    return Repository.get(`${supplier}`, params);
  },
  getPricingGroups(params) {
    return Repository.get(`${pricingGroup}`, params);
  },
  getTerritories(params) {
    return Repository.get(`${territory}`, params);
  },
  getSales(params) {
    return Repository.get(`${sales}`, params);
  },
  getInvoiceByCode(code, params) {
    return Repository.get(`${invoice}/${code}`, params);
  },
  getCustomersByID(ids) {
    const parseIds = ids.join(",");
    return Repository.get(`${customer}/customer-by-ids?ids=${parseIds}`);
  },
  getMasterReason(params) {
    return Repository.get(`${reason}`, params);
  },
  getCustomerAddressShipTo(params) {
    return Repository.get(`${customerAddress}/ship-to`, params);
  },
  getCustomers(params) {
    return Repository.get(`${customer}`, {
      params: params,
    });
  },
  getWarehouseTerritory(params) {
    return Repository.get(`${warehouseTerritory}`, {
      params: params,
    });
  },
  getTableItemUnit(params) {
    return Repository.get(`${itemUnit}`, {
      params: params,
    });
  },
  getItemUnitByIDV2(id) {
    return Repository.get(`${itemUnitV2}/${id}`);
  },
  getItems(params) {
    return Repository.get(`${item}`, params);
  },
  getItemUnitByItem(ID) {
    return Repository.get(`${itemUnitByItem}/${ID}`);
  },
  getCustomerGroups(params) {
    return Repository.get(`${customerGroup}`, params);
  },
  getCustomersV2(params) {
    return Repository.get(`${customerv2}`, params);
  },
  getCustomerBillTo(params) {
    return Repository.get(`${customerBillTo}`, {
      params: params,
    });
  },
  getTaxes(params) {
    return Repository.get(`${tax}`, params);
  },
  getTaxesByID(id) {
    return Repository.get(`${tax}/${id}`);
  },
  getMasterReasonByID(id) {
    return Repository.get(`${reason}/${id}`);
  },
  getCustomerAddressByID(id) {
    return Repository.get(`${customerAddress}/${id}`);
  },
  getCustomersByCodes(codes) {
    const parseCodes = codes.join(",");
    return Repository.get(`${customer}/customer-by-codes?codes=${parseCodes}`);
  },
};
